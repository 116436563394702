<template>
  <b-card :title="titolo">
    <b-card-text class="mb-2">
      Regole per il cambio password:
      <ul>
        <li>Lunghezza minima di 6 caratteri</li>
        <li>Password alpha-numerica con almeno 1 lettera mauscola, 1 lettere maiuscola, 1 carattere speciale e 1 numero</li>
      </ul>
      <b-alert
        variant="primary"
        show
        >
        <div class="alert-body font-small-2">
            <small><span class="font-weight-bold">esempio psw:</span> 7BnMVC-iLmDrQ8%BY9</small>
        </div>
        </b-alert>
    </b-card-text>
    <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <b-col md="6">
                <b-form-group
                    label-for="account-new-password"
                    label="Nuova Password *"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="Nuova Password"
                    vid="newPassword"
                    rules="required|min:6|password"
                    >
                        <b-input-group class="input-group-merge">
                            <b-form-input
                                id="account-new-password"
                                v-model="newPasswordValue"
                                :state="errors.length > 0 ? false:null"
                                :type="passwordFieldTypeNew"
                                name="new-password"
                            />
                            <b-input-group-append is-text>
                                <feather-icon
                                :icon="passwordToggleIconNew"
                                class="cursor-pointer"
                                @click="togglePasswordNew"
                                />
                            </b-input-group-append>
                        </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>

                <b-col md="6">
                <b-form-group
                    label-for="account-retype-new-password"
                    label="Riscrivi Password *"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="Nuova Password"
                    rules="required||confirmed:newPassword"
                    >
                        <b-input-group class="input-group-merge">
                            <b-form-input
                                id="account-retype-new-password"
                                v-model="RetypePassword"
                                :type="passwordFieldTypeRetype"
                                name="retype-password"
                            />
                            <b-input-group-append is-text>
                                <feather-icon
                                :icon="passwordToggleIconRetype"
                                class="cursor-pointer"
                                @click="togglePasswordRetype"
                                />
                            </b-input-group-append>
                        </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                
                <b-col cols="12">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-1 mr-1"
                    :disabled="disableButton"
                    @click.prevent="validationForm()"
                >
                    <div v-if="loading === true">
                        <b-spinner small class="mr-50" />
                        Operazione in corso
                    </div>
                    <div v-if="loading === false">
                        Salva
                    </div>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                    class="mt-1"
                    :disabled="disableButton"
                    @click.prevent="ResetForm()"
                >
                    Annulla
                </b-button>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BInputGroup, BInputGroupAppend, BAlert, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required, digits, alphaDash, length, confirmed, password, min
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      disableButton: false,
      loading: false,
      titolo: "",
      required,
      confirmed,
      password, 
      alphaDash, 
      digits,
      length,
      min,
    }
  },
  created() {
    console.log(this.userData)
    console.log("ID -> "+this.userData.id)
    this.titolo = "Salve "+this.userData.name;

    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          console.log('start chiamata api per cambio password')
          console.log(this.newPasswordValue)

          this.disableButton = true
          this.loading = true;

          this.$http.post('api/auth/chpasswd/'+this.userData.id, {
            password: this.newPasswordValue
            }).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)
              
              //rimuovi loading sul bottone
              this.disableButton = false
              this.loading = false;

              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                if(response.data.reply.userData.id > 0){
                    this.$swal({
                        icon: 'success',
                        title: 'Password aggiornata correttamente',
                        confirmButtonText: 'chiudi',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })
                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore',
                      text: 'Aggiornamento password NON riuscito - errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-warning',
                      },
                  })
                }
                
              } else {
                //risposta negativa (errore sul server)
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: 'Operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-danger',
                    },
                })
              }
            }).catch(e => {
              console.log(e);
            });
        }
      })
    },
    ResetForm() {
        this.newPasswordValue = '';
        this.RetypePassword = '';
    }
  },
}
</script>
